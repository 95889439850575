import { BadgeComponent, BadgeRemovable } from '../../../../../../components/Badge';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import { Filter, filterType } from '../../../../types/Filter.Helper';

type FilteredByWidgetsProps = {
  filters: filterType[];
  filterRemoveHandler: any;
  clearFiltersHandler: any;
};

const FilteredByWidgets = ({
  filters,
  filterRemoveHandler,
  clearFiltersHandler,
}: FilteredByWidgetsProps) => {
  const { filters: StrapiFilters } = useStrapiCoursesData().allCourses;
  const { clearAllFiltersButton, noFiltersAppliedText, filterByLabel } = StrapiFilters;
  const selectAll = 'SELECT_ALL';
  return (
    <div className="flex h-fit flex-wrap items-center gap-2">
      <p className="text-xs text-zinc-200">{filterByLabel}:</p>
      {filters && filters.length !== 0 ? (
        filters.map(
          (f) =>
            f.type !== Filter.SEARCH &&
            f.value !== selectAll && (
              <BadgeRemovable filterRemoveHandler={filterRemoveHandler} key={f.value} filter={f} />
            )
        )
      ) : (
        <p className="p-1.5 text-xs text-zinc-500">{noFiltersAppliedText}</p>
      )}
      {filters.length !== 0 && (
        <BadgeComponent
          className="badge flex cursor-pointer flex-row items-center gap-2 bg-zinc-700 p-3 text-xs text-zinc-200"
          label={clearAllFiltersButton}
          onClick={clearFiltersHandler}
        />
      )}
    </div>
  );
};

export default FilteredByWidgets;
