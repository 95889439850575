import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Check, ChevronDown } from 'lucide-react';
import { useStrapiCoursesData } from '../../features/courses/hooks/useStrapiCourseData';
type Option = {
  display: React.ReactNode;
  value: string;
};

type CheckboxSelectProps = {
  className?: string;
  onChange: (values: string[]) => void;
  label?: string;
  options: Option[];
  optionClassName?: string;
  bgClassName?: string;
  value?: string[];
  selectItemLabel?: string;
  disabledOptions?: string[];
  isSelectAllSelected?: boolean;
};

const CheckboxSelect = ({
  className,
  value = [],
  selectItemLabel = 'Select Items',
  onChange,
  label,
  options,
  optionClassName,
  disabledOptions = [],
  isSelectAllSelected = false,
}: CheckboxSelectProps) => {
  const { numberOfItemsSelectedLabel } = useStrapiCoursesData().allCourses.filters;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(value);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const handleOptionClick = (optionValue: string) => {
    if (disabledOptions.includes(optionValue)) return;

    const newSelectedValues = selectedValues.includes(optionValue)
      ? selectedValues.filter((v) => v !== optionValue)
      : [...selectedValues, optionValue];

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  const getDisplayText = () => {
    if (selectedValues.length === 0) return label ?? selectItemLabel;
    if (selectedValues.length === 1) {
      return options.find((opt) => opt.value === selectedValues[0])?.display;
    }
    if (!isSelectAllSelected) return `${selectedValues.length} ${numberOfItemsSelectedLabel}`;
    else return `${selectedValues.length - 1} ${numberOfItemsSelectedLabel}`;
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        className={twMerge(
          `flex min-w-[160px] items-center justify-between border-[0.837607px] border-zinc-800 bg-zinc-900 py-1.5 pl-4 pr-3 text-[11.7px] font-normal leading-[17px] text-zinc-200`,
          className
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="mr-2 truncate">{getDisplayText()}</span>
        <ChevronDown className="h-4 w-4" />
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-1 w-full min-w-[160px] rounded-sm border border-zinc-800 bg-zinc-900 shadow-lg">
          <div className="max-h-60 overflow-auto py-1">
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                className={clsx(
                  'flex cursor-pointer items-center px-3 py-1.5 text-[11.7px] text-zinc-200',
                  'hover:bg-zinc-800',
                  optionClassName,
                  {
                    'cursor-not-allowed opacity-50': disabledOptions.includes(option.value),
                  }
                )}
              >
                <div
                  className={clsx(
                    'mr-2 flex h-3.5 w-3.5 items-center justify-center rounded-sm border border-zinc-600',
                    selectedValues.includes(option.value) && 'bg-zinc-600'
                  )}
                >
                  {selectedValues.includes(option.value) && (
                    <Check className="h-2.5 w-2.5 text-zinc-200" />
                  )}
                </div>
                <span>{option.display}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckboxSelect;
