import clsx from 'clsx';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterDropDown } from '../../../../../components/DataTables';
import SearchInput from '../../../../../components/SearchInput';
import { USHG_ADMIN_STATUS_QUERY_PARAM } from '../../../constants/USHGAdmin';
import { Option } from '../../../../../components/Select';
import { useStrapiHQAdminCoursesData } from '../../../../course-management/hooks/useStrapiHQAdminCourseData';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { REQUESTS_SORT_QUERY_PARAM } from '../../../../requests/constants';
interface Props {
  className?: string;
  status: string | undefined | null;
  search: string;
  isFilterApplied: boolean;
  coursesSortOptions: Option[];
  sort: string;
  sortLabel: string;
}

const CoursesMainHeader = (props: Props) => {
  const { className, status, search, isFilterApplied, coursesSortOptions, sort, sortLabel } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set('search', value);
    navigate('?' + searchParams.toString());
  };
  const handleStatusChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set(USHG_ADMIN_STATUS_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };
  const handleSortByChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set(REQUESTS_SORT_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };
  const handleResetFiltersBtnClick = () => {
    navigate('.');
  };
  const {
    courseTable: {
      CourseTableStatus: { allStatusLabel, liveStatusLabel, draftStatusLabel, statusPlaceholder },
    },
  } = useStrapiHQAdminCoursesData();
  const {
    tableProps: { clearAllFiltersButton, searchPlaceHolder },
  } = useStrapiSharedData();
  const USHG_ADMIN_COURSES_STATUS_VALUES: Option[] = [
    {
      display: allStatusLabel,
      value: 'all',
    },
    {
      display: liveStatusLabel,
      value: 'live',
    },
    {
      display: draftStatusLabel,
      value: 'draft',
    },
  ];
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-between gap-2 text-[14px] font-normal leading-[20px] text-zinc-100 sm:flex-row',
        className
      )}
    >
      <div className="flex flex-col gap-2 lg:flex-row">
        <SearchInput
          placeholder={searchPlaceHolder}
          containerClassName="min-w-[200px]"
          iconClassName="left-2"
          className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
          value={search ?? ''}
          onChange={handleQChange}
        />

        <FilterDropDown
          value={status ?? undefined}
          options={USHG_ADMIN_COURSES_STATUS_VALUES}
          onChange={handleStatusChange}
          selectItemLabel={statusPlaceholder}
          placeholder={statusPlaceholder}
          aria-label="Courses status filter"
          className="rounded-sm border border-zinc-900 bg-zinc-900 py-2 pl-4 pr-10 text-[14px] font-normal leading-[20px] text-zinc-100"
        />
        {isFilterApplied && (
          <div className="order-3 flex w-full grow items-center justify-center md:order-2 md:justify-start">
            <button
              className="h-min self-center justify-self-start rounded-[32px] bg-zinc-700 py-1.5 px-2.5 font-sans text-[10px] font-medium leading-[105%] text-zinc-200"
              onClick={handleResetFiltersBtnClick}
              // When there are no query params disable it
              // As we are setting the filters using the search params
            >
              {clearAllFiltersButton}
            </button>
          </div>
        )}
        <div className="order-2 flex items-center gap-2 md:order-3"></div>
      </div>
      <div className="order-2 flex items-center gap-2 md:order-3">
        <span className="self-center whitespace-nowrap text-[14px] font-normal leading-[20px] text-zinc-100">
          {sortLabel}
        </span>
        <FilterDropDown
          value={sort}
          aria-label="Requests sort options"
          options={coursesSortOptions}
          onChange={handleSortByChange}
          className="grow"
        />
      </div>
    </div>
  );
};
export default CoursesMainHeader;
