import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PaginationFooter } from '../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useAppSelector, useAppDispatch } from '../../../../../hooks';
import useDebounce from '../../../../../hooks/use-debounce';
import { RootState } from '../../../../../store';
import { Option } from '../../../../../components/Select';

import { SECTIONS } from '../../../constants';

import {
  USHG_ADMIN_DEFAULT_PAGE,
  USHG_ADMIN_MAX_PAGE_SIZE,
  USHG_ADMIN_MIN_PAGE_SIZE,
  USHG_ADMIN_PAGE_SIZE_INCREMENT,
} from '../../../constants/USHGAdmin';
import useHQAdminCourseQuery from '../../../hooks/useHQAdminCoursesQuery';
import { triggerCoursesRefresh } from '../../../slices/courses.slice';

import CoursesEmptyState from './CoursesEmptyPage';
import CoursesErrorState from './CoursesErrorpage';
import CoursesMainHeader from './CoursesMainHeader';
import CoursesTable from './CoursesTable';
import { useStrapiRequestData } from '../../../../requests/hooks/useStrapiRequestData';

interface Props {
  className?: string;
}

const COURSES_SORT_QUERY_PARAM = 'sort';
const ENT_ADMIN_COURSES_SORT_BY_VALUES = ['MOST_RECENT', 'EARLIEST', ''] as const;
const USHG_ADMIN_COURSES_SORT_BY_VALUES = ENT_ADMIN_COURSES_SORT_BY_VALUES;
const USHG_ADMIN_DEFAULT_COURSES_SORT_BY = '';

const courseStateSelector = (state: RootState) => state.courses;

const CoursesTableMain = ({ className }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();

  const { sortKey, sortOrder, courseRefreshState } = useAppSelector(courseStateSelector);

  // Memoized and stable state calculations
  const status = useMemo(() => searchParams.get('state') ?? undefined, [location.search]);
  const search = useMemo(() => searchParams.get('search') ?? undefined, [location.search]);
  const debounceSearch = useDebounce(search);

  const [page, setPage] = useState(USHG_ADMIN_DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(USHG_ADMIN_MIN_PAGE_SIZE);

  const { HQTableSortOptions } = useStrapiRequestData();
  const { mostRecentSortOption, oldestSortOption, sortLabel } = HQTableSortOptions;
  const [selectedSort, setSelectedSort] = useState<string>(USHG_ADMIN_DEFAULT_COURSES_SORT_BY);
  const [lastChangedFilter, setLastChangedFilter] = useState<'sortKey' | 'sort'>('sort');
  let currentSort = searchParams.get(COURSES_SORT_QUERY_PARAM);

  // Stable sort calculation
  const sort = useMemo(() => {
    if (
      currentSort == null ||
      !USHG_ADMIN_COURSES_SORT_BY_VALUES.includes(
        currentSort as (typeof ENT_ADMIN_COURSES_SORT_BY_VALUES)[number]
      )
    ) {
      currentSort = USHG_ADMIN_DEFAULT_COURSES_SORT_BY;
    } else if (currentSort != selectedSort) {
      setSelectedSort(currentSort as string);
      setLastChangedFilter('sort');
    }

    return currentSort;
  }, [searchParams, sortKey, sortOrder, currentSort]);

  const COURSES_SORT_SELECT_OPTIONS: Option[] = [
    { display: sortLabel, value: '' },
    { display: mostRecentSortOption, value: 'MOST_RECENT' },
    { display: oldestSortOption, value: 'EARLIEST' },
  ];

  // Consolidated sort logic with filter tracking
  const { newSortKey, newSortOrder, _isSortKeyFilterActive, _isSortFilterActive } = useMemo(() => {
    let sortKeyMem = '';
    let sortOrderMem: string | boolean = '';
    let sortKeyFilterActive = false;
    let sortFilterActive = true; // Default to sort filter being active

    if (sortKey) {
      sortKeyFilterActive = true;
      sortFilterActive = false;

      if (sortOrder === false) {
        sortOrderMem = 'DESC';
        sortKeyMem = sortKey;
        setLastChangedFilter('sortKey');
      } else if (sortOrder === 'desc') {
        sortKeyMem = '';
        sortOrderMem = '';
        sortKeyFilterActive = false;
        sortFilterActive = true;
      } else {
        sortOrderMem = 'ASC';
        sortKeyMem = sortKey;
        setLastChangedFilter('sortKey');
      }
    }

    return {
      newSortKey: sortKeyMem,
      newSortOrder: sortOrderMem as string,
      _isSortKeyFilterActive: sortKeyFilterActive,
      _isSortFilterActive: sortFilterActive,
    };
  }, [sortKey, sortOrder]);

  // Build filters with memoization and prioritized filter selection
  const filters = useMemo(() => {
    const baseFilters = {
      status: status === 'all' ? undefined : status,
      search: debounceSearch,
      start: (page - 1) * pageSize,
      size: pageSize,
    };

    // Prioritize the last changed filter
    if (lastChangedFilter === 'sortKey' && newSortKey && newSortOrder) {
      return {
        ...baseFilters,
        sortOrder: newSortOrder,
        sortKey: newSortKey,
      };
    } else if (lastChangedFilter === 'sort' && sort) {
      return {
        ...baseFilters,
        sort,
      };
    }

    // Fallback to default sorting if no filter is active
    if (newSortKey && newSortOrder) {
      return {
        ...baseFilters,
        sortOrder: newSortOrder,
        sortKey: newSortKey,
      };
    } else {
      return {
        ...baseFilters,
        sortOrder: 'UPDATED_ON',
        sortKey: 'ASC',
      };
    }
  }, [status, page, pageSize, debounceSearch, newSortOrder, newSortKey, sort, lastChangedFilter]);

  const request = useHQAdminCourseQuery({ filters });

  const displayLoader = useMemo(
    () => (request.isLoading && !request.data) || (request.isFetching && request.data?.count === 0),
    [request.data, request.isFetching, request.isLoading]
  );

  const isEmptyState = useMemo(() => request.data && request.data.count === 0, [request.data]);

  const _isFilterApplied = useMemo(
    () => status !== undefined || search !== undefined,
    [status, search]
  );

  // Refresh effect with cleanup
  useEffect(() => {
    if (courseRefreshState?.USHG_ADMIN_COURSES_LIST_TABLE) {
      const refetchAndClear = async () => {
        await request.refetch();
        dispatch(
          triggerCoursesRefresh({
            [SECTIONS.USHG_ADMIN_COURSES_LIST_TABLE]: false,
          })
        );
      };

      refetchAndClear();
    }
  }, [courseRefreshState, dispatch, request]);

  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-center gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <CoursesMainHeader
        status={status}
        search={search ?? ''}
        isFilterApplied={_isFilterApplied}
        sortLabel={sortLabel}
        sort={sort}
        coursesSortOptions={COURSES_SORT_SELECT_OPTIONS}
      />
      {displayLoader && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {request.data && request.data?.count > 0 && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            request.isPreviousData && request.isFetching && 'pointer-events-none opacity-50'
          )}
        >
          <div className="w-full overflow-y-scroll">
            <CoursesTable data={request.data?.data} />
          </div>
          <PaginationFooter
            setPage={setPage}
            activePage={page}
            totalItems={request?.data?.count}
            pageSize={pageSize}
            setPageSize={setPageSize}
            maxPageSize={USHG_ADMIN_MAX_PAGE_SIZE}
            minPageSize={USHG_ADMIN_MIN_PAGE_SIZE}
            pageSizeIncrement={USHG_ADMIN_PAGE_SIZE_INCREMENT}
          />
        </div>
      )}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <CoursesEmptyState isFilterApplied={_isFilterApplied} />
        </div>
      )}
      {request.isError && !request.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <CoursesErrorState isRefetching={request.isRefetching} refetch={request.refetch} />
        </div>
      )}
    </main>
  );
};

export default CoursesTableMain;
