import { selectOptionType } from '../types/Filter.Helper';
import { useStrapiCoursesData } from './useStrapiCourseData';

type useFilterStateProps = {
  tagFetch: string[];
  categoriesFetch: string[];
  speakersFetch: string[];
  formatsFetch: string[];
  tagsOptions?: selectOptionType[];
  categoriesOptions?: selectOptionType[];
  formatsOptions?: selectOptionType[];
  speakersOptions?: selectOptionType[];
};

const createOptions = (
  items: string[] = [],
  selectAllLabel: string,
  existingOptions?: selectOptionType[]
): selectOptionType[] => {
  // If existing options are empty or undefined, create default with 'Select All'
  if (!existingOptions || existingOptions.length === 0) {
    if (!items.length) {
      return [{ display: selectAllLabel, value: 'SELECT_ALL' }];
    }

    return [
      { display: selectAllLabel, value: 'SELECT_ALL' },
      ...items.map((item) => ({
        display: item,
        value: item,
      })),
    ];
  }

  // Create a set of existing values to check for duplicates
  const existingValues = new Set(existingOptions.map((opt) => opt.value));

  // Filter and map new unique items
  const newItems = items.filter((item) => !existingValues.has(item));

  // If no new items, return existing options
  if (newItems.length === 0) {
    return existingOptions;
  }

  // Combine existing options with new items
  return [
    ...existingOptions,
    ...newItems.map((item) => ({
      display: item,
      value: item,
    })),
  ];
};

const useFilterState = ({
  tagFetch,
  categoriesFetch,
  formatsFetch,
  speakersFetch,
  tagsOptions,
  categoriesOptions,
  formatsOptions,
  speakersOptions,
}: useFilterStateProps) => {
  // Get select all label from Strapi course data
  const { selectAllLabel } = useStrapiCoursesData().allCourses.filters;

  // Generate options, using existing options if provided
  const tags = createOptions(tagFetch, selectAllLabel, tagsOptions);
  const categories = createOptions(categoriesFetch, selectAllLabel, categoriesOptions);
  const formats = createOptions(formatsFetch, selectAllLabel, formatsOptions);
  const speakers = createOptions(speakersFetch, selectAllLabel, speakersOptions);

  return { tags, categories, formats, speakers };
};

export default useFilterState;
