import { useEffect, useState } from 'react';
import { SearchInput } from '../../../common';
import { Filter, filterType, selectOptionType } from '../../../../types/Filter.Helper';
import { RootState } from '../../../../../../store';
import { useAppSelector } from '../../../../../../hooks';
import useFilterState from '../../../../hooks/useFilterState';
import { GetDurationSortRange } from '../../../../data/DurationRange';
import { COURSES_SORT, COURSE_SEARCH_DELAY } from '../../../../constants/course';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import useDebounce from '../../../../../../hooks/use-debounce';
import CheckboxSelect from '../../../../../../components/CheckboxSelect';
import Select from '../../../../../../components/Select';

type FilterWidgetsProps = {
  appliedFilters: filterType[];
  filterApplyHandler: any;
  search: string;
  setSearch: any;
  searchClear: any;
};

const FilterWidgets = ({
  appliedFilters,
  filterApplyHandler,
  search,
  setSearch,
  searchClear,
}: FilterWidgetsProps) => {
  const {
    filters,
    sort,
    search: { searchPlaceHolder },
  } = useStrapiCoursesData().allCourses;

  const {
    ascendingSortLabel,
    descendingSortLabel,
    shortestToLongestSortLabel,
    longestToShortestSortLabel,
    sortPlaceHolder,
  } = sort;
  const { speakersLabel, tagsLabel, categoriesLabel, formatLabel, durationLabel, selectAllLabel } =
    filters;

  // State for multi-select values
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>([]);
  const [selectedFormats, setSelectedFormats] = useState<string[]>([]);
  const [selectedDurations, setSelectedDurations] = useState<string[]>([]);
  const [isSelectAllCategoriesSelected, setIsSelectAllCategoriesSelected] =
    useState<boolean>(false);
  const [isSelectAllTagsSelected, setIsSelectAllTagsSelected] = useState<boolean>(false);
  const [isSelectAllFormatsSelected, setIsSelectAllFormatsSelected] = useState<boolean>(false);
  const [isSelectAllSpeakersSelected, setIsSelectAllSpeakersSelected] = useState<boolean>(false);
  const [isSelectAllDurationSelected, setIsSelectAllDurationSelected] = useState<boolean>(false);
  // State for persistent options
  const [tagsOptions, setTagsOptions] = useState<selectOptionType[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<selectOptionType[]>([]);
  const [formatsOptions, setFormatsOptions] = useState<selectOptionType[]>([]);
  const [speakersOptions, setSpeakersOptions] = useState<selectOptionType[]>([]);
  const sortBy: selectOptionType[] = [
    {
      display: sortPlaceHolder,
      value: Filter.NONE,
    },
    {
      display: ascendingSortLabel,
      value: COURSES_SORT.NAME.ASCENDING,
    },
    {
      display: descendingSortLabel,
      value: COURSES_SORT.NAME.DESCENDING,
    },
    {
      display: shortestToLongestSortLabel,
      value: COURSES_SORT.DURATION.ASCENDING,
    },
    {
      display: longestToShortestSortLabel,
      value: COURSES_SORT.DURATION.DESCENDING,
    },
  ];

  const DURATION_SORT_RANGE = GetDurationSortRange();
  const filterByDuration: selectOptionType[] = [
    { display: selectAllLabel, value: 'SELECT_ALL' },
    ...DURATION_SORT_RANGE,
  ];
  const durations = filterByDuration;

  useEffect(() => {
    const categoryFilters = appliedFilters.filter((f) => f.type == Filter.CATEGORIES);
    const appliedCategories = categoryFilters.map((category) => category.value);
    const tagFilters = appliedFilters.filter((f) => f.type == Filter.TAGS);
    const appliedTags = tagFilters.map((tag) => tag.value);
    const formatFilters = appliedFilters.filter((f) => f.type == Filter.FORMATS);
    const appliedFormats = formatFilters.map((format) => format.value);
    const speakerFilters = appliedFilters.filter((f) => f.type == Filter.SPEAKERS);
    const appliedSpeakers = speakerFilters.map((speaker) => speaker.value);
    const durationFilters = appliedFilters.filter((f) => f.type == Filter.DURATION);
    const appliedDurations = durationFilters.map((duration) => duration.value);
    if (appliedCategories.length != selectedCategories.length)
      handleCategoryChange(appliedCategories);
    if (appliedTags.length != selectedTags.length) handleTagChange(appliedTags);
    if (appliedFormats.length != selectedFormats.length) handleFormatChange(appliedFormats);
    if (appliedSpeakers.length != selectedSpeakers.length) handleSpeakerChange(appliedSpeakers);
    if (selectedDurations.includes('SELECT_ALL')) {
      appliedDurations.push('SELECT_ALL');
      if (appliedDurations.length != selectedDurations.length)
        handleDurationChange(appliedDurations);
    } else if (appliedDurations.length != selectedDurations.length)
      handleDurationChange(appliedDurations);
  }, [appliedFilters.length]);

  // Modified to handle multiple selections
  const applyMultiFilter = (values: string[], options: selectOptionType[], filterType: Filter) => {
    if (values.length === 0) {
      // If no values selected, remove all filters of this type
      filterApplyHandler({ type: filterType, action: 'remove-all' });
      return;
    }

    const filters = values
      .map((value) => {
        const element = options.find((l) => l.value === value);
        return element
          ? { type: filterType, display: element.display, value: element.value }
          : null;
      })
      .filter(Boolean);

    // Remove existing filters of this type and add new ones
    filterApplyHandler(filters);
  };

  // Tags Control
  const tagFetch = useAppSelector((state: RootState) => state.courses.tags);
  // Tags Handler
  const handleTagChange = (values: string[]) => {
    // Determine the difference between current state and incoming values
    const addedValues = values.filter((value) => !selectedTags.includes(value));
    const removedValues = selectedTags.filter((value) => !values.includes(value));

    // Scenario 1: Select All
    if (addedValues.includes('SELECT_ALL')) {
      const allTags = tags.map((tag) => tag.value);
      setSelectedTags(allTags);
      applyMultiFilter(allTags, tags, Filter.TAGS);
      setIsSelectAllTagsSelected(true);
      return;
    }

    // Scenario 2: Deselect All (using SELECT_ALL checkbox)
    if (removedValues.includes('SELECT_ALL')) {
      setSelectedTags([]);
      filterApplyHandler({ type: Filter.TAGS, action: 'remove-all' });
      setIsSelectAllTagsSelected(false);
      return;
    }

    // Scenario 3: Selecting 'SELECT_ALL' and then deselecting some items removes 'SELECT_ALL' and keeps the selected items
    if (values.includes('SELECT_ALL') && removedValues.length > 0) {
      const newValues = values.filter((value) => value != 'SELECT_ALL');
      setSelectedTags(newValues);
      applyMultiFilter(newValues, tags, Filter.TAGS);
      setIsSelectAllTagsSelected(false);
      return;
    }

    // Default: Update selected tags
    setSelectedTags(values);
    applyMultiFilter(values, tags, Filter.TAGS);
  };

  // Categories Control
  const categoriesFetch = useAppSelector((state: RootState) => state.courses.categoryName);
  // Categories Handler
  const handleCategoryChange = (values: string[]) => {
    // Determine the difference between current state and incoming values
    const addedValues = values.filter((value) => !selectedCategories.includes(value));
    const removedValues = selectedCategories.filter((value) => !values.includes(value));

    // Scenario 1: Select All
    if (addedValues.includes('SELECT_ALL')) {
      const allCategories = categories.map((category) => category.value);
      setSelectedCategories(allCategories);
      applyMultiFilter(allCategories, categories, Filter.CATEGORIES);
      setIsSelectAllCategoriesSelected(true);
      return;
    }

    // Scenario 2: Deselect All (using SELECT_ALL checkbox)
    if (removedValues.includes('SELECT_ALL')) {
      setSelectedCategories([]);
      filterApplyHandler({ type: Filter.CATEGORIES, action: 'remove-all' });
      setIsSelectAllCategoriesSelected(false);
      return;
    }
    // Scenario 3: Selecting 'SELECT_ALL' and then deselecting some items removes 'SELECT_ALL' and keeps the selected items
    if (values.includes('SELECT_ALL') && removedValues.length > 0) {
      const newValues = values.filter((value) => value != 'SELECT_ALL');
      setSelectedCategories(newValues);
      applyMultiFilter(newValues, categories, Filter.CATEGORIES);
      setIsSelectAllCategoriesSelected(false);
      return;
    }

    // Default: Update selected categories
    setSelectedCategories(values);
    applyMultiFilter(values, categories, Filter.CATEGORIES);
  };

  // Duration Control - multiselect
  const handleDurationChange = (values: string[]) => {
    // Determine the difference between current state and incoming values
    const addedValues = values.filter((value) => !selectedDurations.includes(value));
    const removedValues = selectedDurations.filter((value) => !values.includes(value));

    // Scenario 1: Select All
    if (addedValues.includes('SELECT_ALL')) {
      // Extract all duration values excluding 'SELECT_ALL'
      const allDuration = durations.map((duration) => duration.value);
      const selectedDuration = allDuration.filter((value) => value !== 'SELECT_ALL');
      setSelectedDurations(allDuration);
      applyMultiFilter(selectedDuration, durations, Filter.DURATION);
      setIsSelectAllDurationSelected(true);
      return;
    }
    // Scenario 2: Deselect All (using SELECT_ALL checkbox)
    if (removedValues.includes('SELECT_ALL')) {
      setSelectedDurations([]);
      filterApplyHandler({ type: Filter.DURATION, action: 'remove-all' });
      setIsSelectAllDurationSelected(false);
      return;
    }
    // Scenario 3: Selecting 'SELECT_ALL' and then deselecting some items removes 'SELECT_ALL' and keeps the selected items
    if (values.includes('SELECT_ALL') && removedValues.length > 0) {
      const newValues = values.filter((value) => value != 'SELECT_ALL');
      setSelectedDurations(newValues);
      applyMultiFilter(newValues, durations, Filter.DURATION);
      setIsSelectAllDurationSelected(false);
      return;
    }
    // Default: Update selected speakers
    setSelectedDurations(values);
    applyMultiFilter(values, filterByDuration, Filter.DURATION);
  };

  // Speaker Control
  const speakersFetch = useAppSelector((state: RootState) => state.courses.speakers);
  // Speakers Handler
  const handleSpeakerChange = (values: string[]) => {
    // Determine the difference between current state and incoming values
    const addedValues = values.filter((value) => !selectedSpeakers.includes(value));
    const removedValues = selectedSpeakers.filter((value) => !values.includes(value));

    // Scenario 1: Select All
    if (addedValues.includes('SELECT_ALL')) {
      const allSpeakers = speakers.map((speaker) => speaker.value);
      setSelectedSpeakers(allSpeakers);
      applyMultiFilter(allSpeakers, speakers, Filter.SPEAKERS);
      setIsSelectAllSpeakersSelected(true);
      return;
    }

    // Scenario 2: Deselect All (using SELECT_ALL checkbox)
    if (removedValues.includes('SELECT_ALL')) {
      setSelectedSpeakers([]);
      filterApplyHandler({ type: Filter.SPEAKERS, action: 'remove-all' });
      setIsSelectAllSpeakersSelected(false);
      return;
    }
    // Scenario 3: Selecting 'SELECT_ALL' and then deselecting some items removes 'SELECT_ALL' and keeps the selected items
    if (values.includes('SELECT_ALL') && removedValues.length > 0) {
      const newValues = values.filter((value) => value != 'SELECT_ALL');
      setSelectedSpeakers(newValues);
      applyMultiFilter(newValues, speakers, Filter.SPEAKERS);
      setIsSelectAllSpeakersSelected(false);
      return;
    }

    // Default: Update selected speakers
    setSelectedSpeakers(values);
    applyMultiFilter(values, speakers, Filter.SPEAKERS);
  };

  // Format Control
  const formatsFetch = useAppSelector((state: RootState) => state.courses.format);
  // Formats Handler
  const handleFormatChange = (values: string[]) => {
    // Determine the difference between current state and incoming values
    const addedValues = values.filter((value) => !selectedFormats.includes(value));
    const removedValues = selectedFormats.filter((value) => !values.includes(value));

    // Scenario 1: Select All
    if (addedValues.includes('SELECT_ALL')) {
      const allFormats = formats.map((format) => format.value);
      setSelectedFormats(allFormats);
      applyMultiFilter(allFormats, formats, Filter.FORMATS);
      setIsSelectAllFormatsSelected(true);
      return;
    }

    // Scenario 2: Deselect All (using SELECT_ALL checkbox)
    if (removedValues.includes('SELECT_ALL')) {
      setSelectedFormats([]);
      filterApplyHandler({ type: Filter.FORMATS, action: 'remove-all' });
      setIsSelectAllFormatsSelected(false);
      return;
    }

    // Scenario 3: Selecting 'SELECT_ALL' and then deselecting some items removes 'SELECT_ALL' and keeps the selected items
    if (values.includes('SELECT_ALL') && removedValues.length > 0) {
      const newValues = values.filter((value) => value != 'SELECT_ALL');
      setSelectedFormats(newValues);
      applyMultiFilter(newValues, formats, Filter.FORMATS);
      setIsSelectAllFormatsSelected(false);
      return;
    }

    // Fallback: Default handling for other scenarios
    setSelectedFormats(values);
    applyMultiFilter(values, formats, Filter.FORMATS);
  };

  // Sort Control - Keeping as single select
  const [sorts, setSorts] = useState<selectOptionType[]>([]);
  const selectedSortChangeHandler = (e: any) => {
    const element = sorts.find((l) => l.value === e.target.value);
    if (element) {
      filterApplyHandler({ type: Filter.SORT, display: element.display, value: element.value });
    }
  };

  const debounceSearch = useDebounce(search, COURSE_SEARCH_DELAY);

  // Search Control
  const searchHandler = (e: any | null, forceSearch?: boolean) => {
    if (e?.key === 'Enter' || forceSearch) {
      if (search.trim().length !== 0)
        filterApplyHandler({ type: Filter.SEARCH, display: search, value: search });
      else searchClear();
    }
  };

  useEffect(() => {
    searchHandler(null, true);
  }, [debounceSearch]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { tags, speakers, formats, categories } = useFilterState({
    tagFetch,
    categoriesFetch,
    formatsFetch,
    speakersFetch,
    tagsOptions,
    speakersOptions,
    formatsOptions,
    categoriesOptions,
  });
  useEffect(() => {
    setTagsOptions(tags);
    setSpeakersOptions(speakers);
    setFormatsOptions(formats);
    setCategoriesOptions(categories);
  }, [categories, formats, speakers, tags]);

  useEffect(() => {
    setSorts(sortBy);
  }, []);

  // Clear filters function
  const clearFilters = () => {
    setSelectedTags([]);
    setSelectedCategories([]);
    setSelectedSpeakers([]);
    setSelectedFormats([]);
    setSelectedDurations([]);
  };

  // Reset filters when appliedFilters is empty
  useEffect(() => {
    const handleFilterReset = () => {
      if (!filterApplyHandler.length) {
        clearFilters();
      }
    };
    handleFilterReset();
  }, [filterApplyHandler]);

  return (
    <div className="flex flex-wrap justify-between">
      <div className="my-1 flex flex-wrap gap-1.5">
        {categories && (
          <CheckboxSelect
            label={categoriesLabel}
            className="rounded-[1.67521px]"
            onChange={handleCategoryChange}
            options={categories}
            value={selectedCategories}
            isSelectAllSelected={isSelectAllCategoriesSelected}
          />
        )}
        {tags && (
          <CheckboxSelect
            label={tagsLabel}
            className="rounded-[1.67521px]"
            onChange={handleTagChange}
            options={tags}
            value={selectedTags}
            isSelectAllSelected={isSelectAllTagsSelected}
          />
        )}
        {filterByDuration && (
          <CheckboxSelect
            label={durationLabel}
            className="rounded-[1.67521px]"
            onChange={handleDurationChange}
            options={filterByDuration}
            value={selectedDurations}
            isSelectAllSelected={isSelectAllDurationSelected}
          />
        )}
        {speakers && (
          <CheckboxSelect
            label={speakersLabel}
            className="rounded-[1.67521px]"
            onChange={handleSpeakerChange}
            options={speakers}
            value={selectedSpeakers}
            isSelectAllSelected={isSelectAllSpeakersSelected}
          />
        )}
        {formats && (
          <CheckboxSelect
            label={formatLabel}
            className="rounded-[1.67521px]"
            onChange={handleFormatChange}
            options={formats}
            value={selectedFormats}
            isSelectAllSelected={isSelectAllFormatsSelected}
          />
        )}
      </div>
      <div className="my-1 flex flex-wrap gap-1.5">
        {sorts && (
          <Select
            label={sortPlaceHolder}
            className="rounded"
            onChange={selectedSortChangeHandler}
            options={sorts}
          />
        )}
        <SearchInput
          search={search}
          setSearch={setSearch}
          searchHandler={searchHandler}
          placeholder={searchPlaceHolder}
        />
      </div>
    </div>
  );
};

export default FilterWidgets;
