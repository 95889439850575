import { format } from 'date-fns';
import { useLanguage } from '../../../../../shared/context/languageContext';
import { getLocale } from '../../../../../../utils';

interface Props {
  createdOn: string;
}

const CreatedOn = ({ createdOn }: Props) => {
  const { language } = useLanguage();
  return (
    <span className="whitespace-nowrap">
      {format(new Date(createdOn), 'EEE PPpp', { locale: getLocale(language) })}
    </span>
  );
};

export default CreatedOn;
